<template>
	<div>		
		<div class="first-screen-cover" ref="screenCover">
			<div class="photo-cover">
				<div class="photo-box"
					:style="{width: 'calc(100vw * '+imageArray.length+')', marginLeft: 0 - clientWidth * index + 'px'}">
					<div class="photo-item">
						<img src="@/assets/1.jpg" @load="imageLoadOk" v-show="showImage" />
					</div>
				</div>
			</div>
			<div class="first-screen-content">
				<div class="top-bar" v-if="this.clientWidth >= 768">
					<a @click.stop="toPcDownload">点我下载游戏</a>
					<a @click.stop="toQQ">点我加群</a>
					<a @click.stop="toQQKefu">联系客服寻求帮助</a>
				</div>
				<div class="top-bar-mobile" v-if="this.clientWidth < 768">
					<a @click.stop="toAndroidDownload">下载游戏</a>
					<a @click.stop="toQQ">点我加群</a>
					<a @click.stop="toQQKefu">联系客服寻求帮助</a>
					
				</div>
				<div class="download">
					<div class="qr-code">
						<img src="@/assets/qr.png">
					</div>
					<!-- 手机端和QQ -->
					<div class="phone-qq">
						<div class="top-join" id ="join-qq-id" @click="toQQ">
							<img src="@/assets/join_qq.png">
							<img src="@/assets/join_qq_active.png">
						</div>
						<div class="bottom-download" id="android-download-id" @click="toAndroidDownload">
							<img src="@/assets/android_download.png">
							<img src="@/assets/android_download_active.png">
						</div>
					</div>
					<!-- PC -->
					<div class="pc-download" id="pc-download-id" v-if="this.clientWidth >= 768" @click="toPcDownload">
						<img src="@/assets/pc_download.png">
						<img src="@/assets/pc_download_active.png">
					</div>
				</div>
			</div>
		</div>
<!-- 		<div class="show-server-content">
			<div class="container"
				:style="{width: clientWidth >= 1200 ? '1200px' : 'auto', padding: clientWidth >=1200 ? 0 : '0 20px'}">
				<div class="title-box">
					<div class="title-line"></div>
					<p>服务器介绍视频</p>
				</div>

				<a class="second-title" title="视频原地址" href="https://www.bilibili.com/video/BV1kR4y1x7UN"
					target="_blank">
					来自B站UP主 史蒂猪StevenPig 的精心录制视频
				</a>
				<div class="video-cover">
					<video src="https://oss.mu2n.com/video/stevepig.mp4" controls
						poster="https://oss.mu2n.com/video/cover.jpg"></video>
				</div>
			</div>
		</div> -->
		<div class="about">
			<div class="container"
				:style="{width: clientWidth >= 1200 ? '1200px' : 'auto', padding: clientWidth >=1200 ? 0 : '0 20px'}">
				<div class="title-box">
					<div class="title-line"></div>
					<p>ABOUT US</p>
				</div>
				<p class="second-title">
					关于我们的故事
				</p>
				<p class="about-content">
					相信大家小时候都或多或少的看过和了解过宝可梦，不知道那时候留给你影响最深的宝可梦是谁？是小智那只可爱的皮卡丘？还是呆呆傻傻的可达鸭？相信大家回想起来仍然是意犹未尽。有没有想过进入到宝可梦世界中，一路收集各种宝可梦，成为世界最强的训练家？
				</p>
				<p class="about-content">
					现在，梦想成真！得益于Minecraft的模组开发者，目前已经成功将<span>全部八个世代及洗翠地区的宝可梦</span>带入到Minecraft世界中了，这给予了我们亲身体验的机会。而樱花宝可梦在这之上经由多位开发者，花费一年多时间和数十万元资金成功开发出了樱花宝可梦！它拥有多种有趣玩法，并结合了Minecraft<span>高自由度</span>的特性，让玩家在参与宝可梦捕捉、对战之余可以发挥创造力<span>建设自己的家园</span>！
				</p>

				<p class="about-content">
					服务器目前已拥有成熟的体系，稳定的服务器（可支持千人在线不卡顿）以及专业的开发人员（服主二木拥有7年开服经验，3年插件开发经验，开服前为某公司技术架构师）带来的雄厚技术实力，保证服务器 <span>7 * 24</span>
					的稳定运行（每周7天，每天24小时)，给您带来最佳的游戏体验，实现童年时的训练家之梦！
				</p>
				<p class="about-content">
					此外，我们也想打造一个玩家氛围，服务器风气极好的服务器，我们已在此方面做出了极大努力，二木和客服larp会全力保证24小时消息处理率，共同提高大家的游戏体验~ (二木和larp还是很和蔼可亲的哦)
				</p>
			</div>
		</div>
		<div class="why-use">
			<div class="container"
				:style="{width: clientWidth >= 1200 ? '1200px' : 'auto', padding: clientWidth >=1200 ? 0 : '0 20px'}">
				<div class="title-box">
					<div class="title-line"></div>
					<p>我们的优势 & 服务器特点</p>
				</div>
				<div class="why-use-content-box">
					<div class="left-photo" v-if="clientWidth >= 1200">
						<div class="photo-display-cover">
							<img src="@/assets/left_photo.jpg" />
						</div>
					</div>
					<div class="right-content">
						<div class="little-photo-display-cover" v-if="clientWidth < 1200">
							<img src="@/assets/left_photo.jpg" />
						</div>
						<p class="title">与神奇宝贝MOD保持一致更新</p>
						<p class="content">
							截至目前，我们已更新宝可梦MOD至 <span>8.4.2</span> 版本，已拥有了不少来自 <span>洗翠地区</span> 的宝可梦，欢迎各位来服务器体验!
						</p>
						<p class="title">流畅稳定的游戏环境</p>
						<p class="content">
							一直以来，Minecraft单服务端性能一直不是很好，特别是生存服无法使用小游戏类的跨服解决方案。不过，樱花宝可梦经过长时间的研发和摸索，成功将宝可梦服务器进行了集群化，解决了生存类服务器只能单实例部署的难题，实现了千人同服乃至万人同服不卡顿的目标。目前樱花宝可梦拥有<span>6</span>台服务器（<span>6台12900K</span>），每台服务器均搭载三星<span>980PROM.2固态硬盘</span>，且后续会根据服务器人数持续增加服务器，致力为您提供最稳定流畅的Minecraft神奇宝贝服务器。
						</p>

						<p class="title">原创玩法和对战机制</p>
						<p class="content">
							截止到2022年6月11日，樱花宝可梦已经拥有了<span>近百个完全原创插件</span>，从基础上对玩家的惯性体验进行了高达90%的优化，在几乎重构的代码逻辑下，您将体会到前所未有的便捷，这将大幅度帮您获得更佳的操作体验。且服务器拥有交易市场及面对面交易系统，可保证您在游戏交易不受骗，此外如果遇到特殊情况，也可私聊我们的客服协助处理哦~而在提到宝可梦后，首先想到的当然是使用宝可梦进行对战！本服已完美还原本家对战模式（OU、UB、VGC、63），且VGC模式已使用了最新的<span>VGC2022</span>规则，让想玩极巨化的玩家，可以体验到双打极巨化赛制的快乐~ 此外，对于每赛季排名靠前的玩家，我们将会赠送实体奖励(<span>宝可梦周边、手办等</span>)，第一名更有<span>switch</span>相送!
						</p>
						<p class="title">全新经济体系</p>
						<p class="content">
							在经过二木的深思熟虑后，我们创造出了一套能让绝大多数玩家都能获得更好游戏体验的经济货币 -
							<span>樱花币</span>。它将<span>代替传统点券</span>的大部分功能，让您能够通过<span>每日在线、活动、每日及每周任务</span>等就可以得到自己想要的道具，养成宝可梦、提升宝可梦个体等再也不是非点券不行啦~ 并且游戏内的通用货币“金币”可以同其他玩家进行宝可梦交易，亦可以参加拍卖或举办活动来丰富自己的游戏生涯。而使用金币也可以轻松获取炫酷的时装、精灵皮肤等稀有物品，来避免因为囊中羞涩带来的诸多不便。
						</p>
						<p class="title">活跃的开发组及和蔼的管理层</p>
						<p class="content">
							服主二木现全职开发服务器，拥有多年的开发经验和开服经验，技术雄厚~ 同时管理层也会经常活跃于服务器群，我们会聆听各位玩家的建议和反馈，加上二木的开发能力。樱花宝可梦现在可能不是最好的服务器，但我们相信，在管理层和开发组的悉心运营优化和大家的共同努力下，本服务器一定将成为一个您流连忘返的，有着专属于自己回忆的乌托邦。
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="op">
			<div class="container"
				:style="{width: clientWidth >= 1200 ? '1200px' : 'auto', padding: clientWidth >=1200 ? 0 : '0 20px'}">
				<div class="title-box">
					<div class="title-line"></div>
					<p>优秀的服务器管理团队</p>
				</div>
				<div :class="clientWidth >= 1200 ? ['op-content'] : ['op-content', 'op-content-mb']">
					<div class="op-content-item">
						<div class="op-content-top">
							<img src="@/assets/ermu.png" />
						</div>
						<div class="op-content-description">
							<p class="op-name">二木</p>
							<p class="op-description">服主 <span style="font-size: 16px;">&</span> 插件开发</p>
						</div>
					</div>

					<div class="op-content-item">
						<div class="op-content-top">
							<img src="@/assets/larp.png" />
						</div>
						<div class="op-content-description">
							<p class="op-name">Larp</p>
							<p class="op-description">服务器客服</p>
						</div>
					</div>
					
					<div class="op-content-item">
						<div class="op-content-top">
							<img src="@/assets/mavis.png" />
						</div>
						<div class="op-content-description">
							<p class="op-name">King_JY</p>
							<p class="op-description">BUG排查师</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="footer">
			<a href="http://beian.miit.gov.cn/" target="_blank"
				style="color: #fff;margin-bottom: 5px;text-decoration: none;display: block;text-align:center">备案号:
				浙ICP备2024099486号-1</a>
			<p>
				<a class="second-title" title="视频原地址" href="隐私政策.html" target="_blank">
					隐私政策
				</a>
				|
				<a class="second-title" title="视频原地址" href="用户协议.html" target="_blank">
					用户协议
				</a>
				|
				<a class="second-title" title="视频原地址" href="用户权限.html" target="_blank">
					用户权限
				</a>
			</p>
			<p style="margin-top: 25px">应用名称：《樱花宠物小精灵》版本信息: 1.0.0</p>
			<p style="margin-top: 10px">更新时间：2022.06.11 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 开发者: 杭州随便玩科技有限公司西湖区分公司</p>
		</div>
	</div>
</template>

<script>
	export default ({
		data() {
			return {
				clientWidth: document.documentElement.clientWidth,
				clientHeight: document.documentElement.clientHeight,
				imageArray: [
					"assets/1.jpg"
				],
				delay: 15,
				index: 0,
				togglePhotoIndex: -1,
				watchWidthIndex: -1,
				showImage: false
			}
		},
		created() {

		},
		mounted() {
			this.togglePhotoIndex = setInterval(() => {
				this.index++;
				if (this.index >= this.imageArray.length) {
					this.index = 0;
				}
			}, this.delay * 1000)


			this.watchWidthIndex = setInterval(() => {
				let currentWidth = this.$refs.screenCover.clientWidth;
				if (currentWidth == this.clientWidth) {
					return;
				}
				this.clientWidth = currentWidth;
			}, 100)

			window.onresize = () => {
				return (() => {
					this.clientHeight = document.documentElement.clientHeight; // 高
				})()
			};
		},
		destroyed() {
			window.clearInterval(this.togglePhotoIndex);
			window.clearInterval(this.watchWidthIndex);
		},
		methods: {
			imageLoadOk(param) {
				this.showImage = true;
			},
			toQQ() {
				_hmt.push(['_trackEvent', '樱花宝可梦', '加群']);
				window.open(
					"http://qm.qq.com/cgi-bin/qm/qr?_wv=1027&k=Jsi0SJsAGuyD26sacnSpfd7RxEAAetbU&authKey=345q%2BWshv3IsiB4kazsTCuiObS0euIn81RTfpHsOYxhPGHuwJthlL%2F%2BMtfxcq7yi&noverify=0&group_code=720024801"
				);
			},
			toLittleSkin(){
				window.open(
					"https://littleskin.cn/"
				);	
			},
			toQQKefu() {
				alert("客服QQ: 1471326733, 进不来等任何问题可联系客服哦~")
			},
			toPcDownload() {
				_hmt.push(['_trackEvent', '樱花宝可梦', '电脑客户端下载']);
				window.open(
					"https://as2.shuibianw01.top/%5B%E7%94%B5%E8%84%91%E7%89%88%5D%E6%A8%B1%E8%8A%B1%E5%AE%9D%E5%8F%AF%E6%A2%A6%E5%AE%89%E8%A3%85%E5%8C%85%28%E6%8E%A8%E8%8D%90%E4%BD%BF%E7%94%A8%E5%8F%8C%E5%87%BB%E5%AE%89%E8%A3%85%20%E5%8F%8C%E5%87%BB%E5%90%8E%E7%A8%8D%E7%AD%89%E4%B8%80%E4%BC%9A%29V1.6.exe"
				);
			},
			toAndroidDownload() {
				_hmt.push(['_trackEvent', '樱花宝可梦', '手机客户端下载']);
				location.href = "https://as2.shuibianw01.top/%5B%E5%AE%89%E5%8D%93%E7%89%88%5D%E6%A8%B1%E8%8A%B1%E5%AE%9D%E5%8F%AF%E6%A2%A6.apk"
			},
			toTop() {
				document.body.scrollTop = document.documentElement.scrollTop = 0;
			}
		}
	})
</script>

<style lang="scss" scoped>
	.first-screen-cover {
		width: 100%;
		height: 100vh;
		position: relative;
		overflow: hidden;

		.photo-cover {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			overflow: hidden;
			background-color: #eeeeee;

			.photo-box {
				height: 100%;
				transition: margin 0.8s;
				display: flex;
				justify-content: flex-start;

				.photo-item {
					width: 100vw;
					height: 100%;
					overflow: hidden;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}
		}

		.first-screen-content {
			width: 100%;
			height: 100%;
			position: relative;

			.top-bar {
				width: 100%;
				height: 66px;
				position: fixed;
				top: 0;
				left: 0;
				background-color: rgba(17, 17, 17, 0.65);
				box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
				transition: opacity 0.5s ease-out;
				z-index: 999;
				display: flex;
				align-items: center;
				padding-left: 10px;
				cursor: pointer;

				a {
					padding: 0px 20px;
					height: 100%;
					line-height: 66px;
					display: block;
					margin-right: 20px;
					color: #ffffff;
					text-decoration: none;
					transition: color 0.3s;
				}

				a:last-child {
					margin-right: 0px;
				}

				a:hover {
					color: #1890ff;
				}
			}
			
			.top-bar-mobile {
				width: 100%;
				height: 66px;
				position: fixed;
				top: 0;
				left: 0;
				background-color: rgba(17, 17, 17, 0.65);
				box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
				transition: opacity 0.5s ease-out;
				z-index: 999;
				display: flex;
				align-items: center;
				padding-left: 10px;
				cursor: pointer;
			
				a {
					padding: 0px 0px;
					height: 100%;
					line-height: 66px;
					display: block;
					margin-right: 20px;
					color: #ffffff;
					text-decoration: none;
					transition: color 0.3s;
					margin-right: 25px;
					font-size: 14px;
				}
			
				a:last-child {
					margin-right: 0px;
				}
			
				a:hover {
					color: #1890ff;
				}
			}

			.download {
				width: 100%;
				height: 125px;
				position: absolute;
				bottom: 8%;
				display: flex;
				justify-content: center;
				align-items: center;

				.qr-code {
					width: 102px;
					height: 102px;
					overflow: hidden;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.phone-qq {
					height: 112px;
					margin-left: 15px;
					display: flex;
					flex-flow: column;
					justify-content: space-between;

					.top-join,
					.bottom-download {
						width: 160px;
						height: 52px;

						cursor: pointer;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}

						img:last-child {
							width: 100%;
							height: 100%;
							object-fit: cover;
							display: none;
						}
					}

					.top-join:hover,
					.bottom-download:hover {
						img {
							display: none;
						}

						img:last-child {
							display: block;
						}
					}
				}

				.pc-download {
					width: 111px;
					height: 111px;
					margin-left: 15px;
					cursor: pointer;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}

					img:last-child {
						width: 100%;
						height: 100%;
						object-fit: cover;
						display: none;
					}
				}

				.pc-download:hover {
					img {
						display: none;
					}

					img:last-child {
						display: block;
					}
				}

			}
		}
	}

	.container {
		margin: 0 auto;
		box-sizing: border-box;
	}

	.title-box {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		p {
			font-size: 24px;
			color: #0b0b0b;
		}

		.title-line {
			width: 4px;
			height: 28px;
			background-color: #1da6ba;
			margin-right: 10px;
		}
	}

	.about {
		width: 100%;
		background-color: #ffffff;
		padding: 64px 0px 64px 0px;

		.second-title {
			margin-top: 20px;
			font-size: 22px;
			color: #525661;
			text-shadow: -0.5ex 0.5ex 4px #eee, 0.5em -0.5ex 2px #d4d4d4;
			margin-bottom: 18px;
		}

		.about-content {
			text-indent: 2em;
			font-size: 14px;
			color: #424242;
			margin-bottom: 28px;
			line-height: 1.5em;

			span {
				font-weight: bolder;
				color: #0b0b0b;
			}
		}

		.about-content:last-child {
			margin-bottom: 0px;
		}
	}

	.why-use {
		width: 100%;
		background-color: #eeeeee;
		padding: 64px 0px 64px 0px;

		.why-use-content-box {
			width: 100%;
			margin-top: 20px;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			.left-photo {
				width: 435px;
				flex-shrink: 0;

				.photo-display-cover {
					margin-left: 10px;
					width: 350px;
					height: 450px;
					border: 3px solid #1da6ba;
					border-radius: 10px;
					transform: rotate(-2deg);
					box-shadow: -0.5ex -0.5ex 4px #eee, 0.5em -0.5ex 2px #ddd;

					img {
						width: 100%;
						height: 100%;
					}
				}
			}

			.right-content {
				flex-grow: 1;
				flex-shrink: 1;

				.little-photo-display-cover {
					margin: 0 auto;
					width: 315px;
					height: 405px;
					border: 3px solid #1da6ba;
					border-radius: 10px;
					box-sizing: border-box;
					box-shadow: -0.5ex -0.5ex 4px #eee, 0.5em -0.5ex 2px #ddd;

					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
					}

					margin-bottom: 15px;
				}

				.title {
					width: 100%;
					text-align: center;
					font-size: 16px;
					font-weight: bold;
					margin-bottom: 15px;
					color: #0b0b0b;
				}

				.content {
					font-size: 14px;
					color: #424242;
					text-indent: 2em;
					line-height: 1.5em;
					margin-bottom: 15px;

					span {
						font-weight: bolder;
						color: #0b0b0b;
					}
				}

				.content:last-child {
					margin-bottom: 0px;
				}
			}
		}
	}

	.op {
		width: 100%;
		background-color: #ffffff;
		padding: 64px 0px 64px 0px;

		.op-content {
			width: 100%;
			display: flex;
			margin-top: 35px;
			justify-content: center;

			.op-content-item {
				width: 200px;
				height: 325px;
				margin-right: 50px;
				border-radius: 10px;
				overflow: hidden;

				box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%),
					0 1px 5px 0 rgb(0 0 0 / 5%),
					0 3px 1px -2px rgb(0 0 0 / 5%);

				.op-content-top {
					width: 100%;
					height: 76%;
					overflow: hidden;
					display: flex;
					justify-content: center;
					padding-top: 10%;
					box-sizing: border-box;
				}

				.op-content-description {
					width: 100%;
					height: 24%;
					display: flex;
					flex-direction: column;
					justify-content: center;

					p {
						width: 100%;
						text-align: center;
					}

					.op-name {
						color: #525661;
						font-size: 18px;
						font-weight: bolder;
					}

					.op-description {
						margin-top: 1px;
						color: #525661;
						font-size: 18px;
					}
				}

			}

			.op-content-item:last-child {
				margin-right: 0px;
			}
		}

		.op-content-mb {
			width: 100%;
			display: block;

			.op-content-item {
				width: 210px;
				margin: 0 auto;
				margin-bottom: 25px;
			}

			.op-content-item:last-child {
				margin: 0 auto;
				margin-bottom: 0;
			}
		}
	}

	.show-server-content {
		width: 100%;
		background-color: #eeeeee;
		padding: 64px 0px 64px 0px;

		.second-title {
			width: 100%;
			display: block;
			margin-top: 20px;
			font-size: 16px;
			color: #525661;
			margin-bottom: 18px;
			text-align: center;
			transition: color 0.3s;
			cursor: pointer;
			text-decoration: none;
		}

		.second-title:hover {
			color: #1890ff;
		}

		.video-cover {
			width: 85%;
			overflow: hidden;
			margin-top: 20px;
			margin: 0 auto;
			overflow: hidden;
			border-radius: 10px;

			video {
				width: 100%;
				object-fit: cover;
			}
		}
	}

	.footer {
		width: 100%;
		background-color: #222222;
		padding: 45px 0;
		font-size: 16px;

		p {
			width: 100%;
			text-align: center;
			color: #ffffff;
		}

		a {
			color: #007bff;
			text-decoration: none;
		}

		a:hover {
			color: #2444c3;
			text-decoration: underline;

		}
	}
</style>
